<template>
  <div class="pt-5">

    <section class="container">
      <div class="card shadow-sm border-0 mt-5">
        <div class="card-body">

          <div class="mb-4 d-flex justify-content-between">
            <h2>Draft Sunting</h2>
            <button @click="submitSunting" class="btn btn-primary" :disabled="checkedSunting.length < 1">Submit</button>
          </div>
          <table class="table table-search">
            <thead>
              <tr>
                <th>
                  <div v-if="checkedSunting.length < 1">#</div>
                  <div v-else>
                    <button @click="clearChecked" class="btn btn-outline-danger btn-sm">Clear</button>
                  </div>
                </th>
                <th>Jabatan</th>
                <th>Sunting</th>
                <th>Tanggal</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in data" :key="idx">
                <td scope="row"><input class="form-check-input" type="checkbox" :value="idx" v-model="checkedSunting"></td>
                <td>{{ item.nama_jabatan ? `${item.nama_jabatan} (${item.kode_jabatan})` : item.informasi_id }}</td>
                <td>{{ getDetailName(item.detail_id) }}</td>
                <td>{{ `${item.updated_at.split('T')[0]}` }}</td>
                <td>
                  <button @click="toSunting(item)" class="btn btn-primary">Detail</button>
                </td>
              </tr>

              <tr v-if="data.length == 0">
                <td colspan="5">
                  <div class="d-flex justify-content-center">Tidak Ada Sunting</div>
                </td>
              </tr>

            </tbody>
          </table>

        </div>

        <div class="d-flex px-3 justify-content-end">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-for="(link, idx) in links" :key="idx">
                <a @click.prevent="toPage(link.label)" class="page-link" :class="{ disabled: !link.active }" href="#" aria-label="Previous" v-if="link.label === '&laquo; Previous'">
                  <span aria-hidden="true">&laquo;</span>
                </a>
                <a @click.prevent="toPage(link.label)" class="page-link" :class="{ disabled: !link.active }" href="#" aria-label="Next" v-else-if="link.label === 'Next &raquo;'">
                  <span aria-hidden="true">&raquo;</span>
                </a>
                <a @click.prevent="toPage(link.label)" class="page-link" :class="{ disabled: link.label == currPage }" href="#" v-else>{{ link.label }}</a>
              </li>
              <!-- <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li> -->
            </ul>
          </nav>
        </div>
      </div>
      

    </section>

    <!-- sunting modal -->
    <div class="modal fade" id="suntingModal" tabindex="-1" aria-labelledby="suntingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl modal-fullscreen-sm-down modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="suntingModalLabel">{{ 'Sunting ' +  modal.title }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <section v-if="modal.detail == 'Kode Jabatan'">
              <table ref="tableScrollable" class="table table-search table-borderless table-striped">
                <thead>
                  <tr>
                    <th>Uraian</th>
                    <th>Suntingan</th>
                    <th>Komentar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in modal.list" :key="idx">
                    <td>
                      <textarea class="form-control" rows="2" readonly v-model="list.currVal"></textarea>
                    </td>
                    <td>
                      <el-select
                        v-model="list.editedVal"
                        clearable
                        filterable
                        :placeholder="''"
                        no-match-text="Tidak ada data yang sesuai"
                        no-data-text="Tidak ada data"
                        remote
                        :remote-method="kodeJabatanMasterSearch"
                        :loading="loadingKodeJabatanSearch"
                        loading-text="Loading"
                      >
                        <el-option v-for="(option, idx) in master.kodeJabatan" :key="idx" :value="option.kode" :label="`${option.kode} ${option.golongan}`"></el-option>
                      </el-select>
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" v-model="list.komentar"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div ref="tableBottom"></div>
            </section>

            <section v-else-if="modal.detail == 'Ringkasan Uraian Tugas' || modal.detail == 'Nama Jabatan'">
              <div v-for="(list, idx) in modal.list" :key="idx">
                <table class="table table-search table-borderless">
                  <thead>
                    <tr>
                      <th v-if="modal.detail == 'Ringkasan Uraian Tugas'">Ringkasan Uraian Tugas</th>
                      <th v-else>Nama Jabatan</th>
                      <th>Suntingan</th>
                      <th>Komentar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <textarea class="form-control" rows="4" readonly v-model="list.currVal"></textarea>
                      </td>
                      <td>
                        <textarea class="form-control" rows="4" v-model="list.editedVal"></textarea>
                      </td>
                      <td>
                        <textarea class="form-control" rows="4" v-model="list.komentar"></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br>
              </div>
            </section>

            <section v-else-if="modal.detail == 'Rincian Tugas' || modal.detail == 'Pengetahuan Kerja'">
              <!-- <div class="d-flex justify-content-end">
                <button @click="modalSuntingAddData(modal.detail)" class="btn btn-primary">Tambah</button>
              </div>
              <br> -->
              <table ref="tableScrollable" class="table table-search table-borderless table-striped">
                <thead>
                  <tr>
                    <th>Uraian</th>
                    <th>Suntingan</th>
                    <th>Komentar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in modal.list" :key="idx">
                    <td>
                      <textarea class="form-control" rows="2" readonly v-model="list.currVal"></textarea>
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" v-model="list.editedVal"></textarea>
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" v-model="list.komentar"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div ref="tableBottom"></div>

            </section>

            <section v-else>

              <!-- <div class="d-flex justify-content-end">
                <button @click="modalSuntingAddData(modal.detail)" class="btn btn-primary">Tambah</button>
              </div>
              <br> -->
              <table class="table table-search table-striped table-borderless table-sm">
                <thead>
                  <tr>
                    <th>Uraian</th>
                    <th>Suntingan</th>
                    <th>Komentar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in modal.list" :key="idx">
                    <td>
                      <textarea class="form-control" rows="2" readonly v-model="list.currVal"></textarea>
                    </td>
                    <td>
                      <el-select
                        v-if="modal.detail == 'Pendidikan'"
                        v-model="list.editedVal"
                        clearable
                        filterable
                        :placeholder="''"
                        no-match-text="Tidak ada data yang sesuai"
                        no-data-text="Tidak ada data"
                        remote
                        :remote-method="pendidikanMasterSearch"
                      >
                        <el-option v-for="(option, idx) in options" :key="idx" :value="option.pendidikan" :label="option[selectedMaster]"></el-option>
                      </el-select>

                      <el-select v-else v-model="list.editedVal" clearable filterable :placeholder="''" no-match-text="Tidak ada data yang sesuai" no-data-text="Tidak ada data">
                        <el-option v-for="(option, idx) in master[selectedMaster]" :key="idx" :value="option[selectedMaster]" :label="`(${option.kode}) ${option[selectedMaster]}`"></el-option>
                      </el-select>
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" v-model="list.komentar"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div ref="tableBottom"></div>
            </section>

          </div>
          <div class="modal-footer">
            <button ref="closeSuntingModalBtn" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
            <button type="button" class="btn btn-primary" @click="submitDraftSunting">Simpan Perubahan Draft Sunting</button>
          </div>
        </div>
      </div>
    </div>

    <button ref="suntingModalBtn" type="button" style="display: none;" data-bs-toggle="modal" data-bs-target="#suntingModal">
    </button>

  </div>
</template>

<script>
import api from './api'
import apiSunting from '../search/api'
import swal from 'sweetalert2'
import { Select, Option, Loading } from 'element-ui'
import auth from '../../helpers/auth.js'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Loading.name]: Loading
  },
  data () {
    return {
      data: [],
      masterDetail: [
        {
          id: 1,
          name: 'Nama Jabatan'
        },
        {
          id: 2,
          name: 'Kode Jabatan'
        },
        {
          id: 3,
          name: 'Ringkasan Uraian Tugas'
        },
        {
          id: 4,
          name: 'Rincian Tugas'
        },
        {
          id: 5,
          name: 'Pendidikan'
        },
        {
          id: 6,
          name: 'Pengetahuan Kerja'
        },
        {
          id: 7,
          name: 'Pelatihan Kerja'
        },
        {
          id: 8,
          name: 'Upaya Fisik'
        },
        {
          id: 9,
          name: 'Bakat'
        },
        {
          id: 10,
          name: 'Minat'
        },
        {
          id: 11,
          name: 'Temperamen'
        }
      ],
      modal: {
        title: 'Test Modal',
        list: [],
        current: [],
        s_log_id: null,
        user_email: null,
        parsePayload: [],
        detail: ''
      },
      master: {
        pendidikan: [],
        syaratfisik: [],
        bakat: [],
        minat: [],
        tempramen: [],
        kodeJabatan: []
      },
      selectedMaster: '',
      loadingKodeJabatanSearch: false,
      checkedSunting: [],
      total: null,
      currPage: null,
      links: [],
    }
  },
  computed: {
    getSuntingParams () {
      // let isSunting = false
      // let isKomentar = false
      // let isTambah = false
      let type = 0
      let detailType = 0
      let updatedPayload = []
      let finalData = []
      let isStop = false

      let detailTypeFind = this.masterDetail.find(item => item.name == this.modal.detail)
      detailType = detailTypeFind?.id ? detailTypeFind.id : 0

      this.modal.list.forEach(data => {
        let isSunting = false
        let isKomentar = false
        let isTambah = false

        let isDataSunting = false
        if (data.editedVal && data.editedVal !== '') {
          isSunting = true
          isDataSunting = true
        } else if (data.komentar && data.komentar !== '') {
          isKomentar = true
          isDataSunting = true
        } else if (data.currVal == `Tambahan data ${this.modal.detail}`) {
          if (data?.currVal == '' || data?.komentar == '') {
            isStop = true
          }
          isTambah = true
          isDataSunting = true
        }

        if (isDataSunting) {
          // updatedPayload.push(data)

          if (isTambah) {
            type = 3
          } else if (isSunting && isKomentar && !isTambah) {
            type = 4
          } else if (isSunting && !isKomentar && !isTambah) {
            type = 1
          } else if (!isSunting && isKomentar && !isTambah) {
            type = 2
          }

          const user = auth.getUser()
          
          const template = {
          informasi_id: this.data.jabatanId,
          detail_id: detailType,
          user_email: user.email,
          // user_email: 'test@mail.com',
          // payload: this.modal.list,
          // title: this.modal.title,
          type_id: type
        }
        
          let payload = {
            fullPayload: this.modal.list,
            suntingOnlyPayload: [data]
          }
          let sunting = { ...template, payload: JSON.stringify(payload) }

          finalData.push(sunting)
        }
      })
      return isStop ? [] : finalData
    },
    getDraftParams () {
      let finalData = []
      const listCheckedData = []
      let checkedId = []
      this.checkedSunting.forEach(idx => {
        checkedId.push(JSON.parse(JSON.stringify(this.data[idx].id)))
      })
      console.log('checkedId', checkedId)
      return checkedId
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const user = auth.getUser()
        const params = {
          user_email: user.email,
          email: user.email,
          token: auth.getSSOToken()
        }
        const data = await api.getAllDraftUserPhp(params, { Authorization: `Bearer ${auth.getToken()}` })
        // const data = await api.getAllDraftUserPhpLocal({ user_email: 'test@mail.com' })
        console.log('data', data)
        let draftList = []
        if (!data) {
          this.data = []
        } else {
          data.forEach(item => {
            const parsedPayload = JSON.parse(item.payload)
            draftList.push({
              ...item,
              parsePayload: parsedPayload,
              nama_jabatan: parsedPayload.suntingOnlyPayload[0].nama_jabatan ? parsedPayload.suntingOnlyPayload[0].nama_jabatan : '',
              kode_jabatan: parsedPayload.suntingOnlyPayload[0].kode_jabatan ? parsedPayload.suntingOnlyPayload[0].kode_jabatan : '',
              checked: false
            })
          })

          console.log('draftList', draftList)
          this.data = JSON.parse(JSON.stringify(draftList))
        }
      } catch (err) {
        console.log('err', err)
      }
    },
    getDetailName (detailType) {
      const index = this.masterDetail.findIndex(item => item.id == detailType)
      if (index !== -1) {
        return this.masterDetail[index].name
      } else {
        return '-'
      }
    },
    async submitDraftSunting () {
      const closeModal = this.$refs.closeSuntingModalBtn

      let noChange = true
      for (const key in this.modal.list[0]) {
        if (this.modal.list[0][key] !== this.modal.current[0][key]) {
          noChange = false
        }
      }

      if (noChange) {
        swal.fire({
          text: 'Tidak Ada Perubahan Suntingan',
          icon: 'info',
          confirmButtonText: 'Oke',
          confirmButtonColor: '#15406A',
        })
        return ''
      }

      const swalRes = await swal.fire({
        title: 'Submit Perubahan Sunting?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Batal',
        confirmButtonColor: '#15406A',
        cancelButtonColor: '#d33'
      })
      if (!swalRes.isConfirmed) return ''
      const loadprg = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      try {
        const user = auth.getUser()

        const payload = {
          fullPayload: this.modal.parsePayload.fullPayload,
          suntingOnlyPayload: this.modal.list
        }
        const params = {
          payload: JSON.stringify(payload),
          user_email: this.modal.user_email,
          email: user.email,
          token: auth.getSSOToken()
        }
        console.log('payload', this.modal.s_log_id, params)
        const res = await apiSunting.editSunting(this.modal.s_log_id, params, { Authorization: `Bearer ${auth.getToken()}` })
        this.getData()
        swal.fire({
          icon: 'success',
          title: 'Sunting Berhasil',
          text: 'Berhasil melakukan perubahan data sunting',
          // confirmButtonColor: '#15406A',
        })
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          text: 'Gagal melakukan perubahan data sunting'
        })
      } finally {
        loadprg.close()
        closeModal.click()
      }
    },
    async submitSunting () {
      const checkedId = this.getDraftParams
      console.log ('submited params', checkedId)
      const closeModal = this.$refs.closeSuntingModalBtn

      const swalRes = await swal.fire({
        title: 'Submit Sunting?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Batal',
        confirmButtonColor: '#15406A',
        cancelButtonColor: '#d33'
      })
      if (!swalRes.isConfirmed) return ''
      const loadprg = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      try {
        const user = auth.getUser()

        const params = {
          ids: checkedId,
          email: user.email,
          token: auth.getSSOToken()
        }
        console.log('params', params)
        const res = await apiSunting.submitDraftSunting(params, { Authorization: `Bearer ${auth.getToken()}` })
        // const res = await apiSunting.submitDraftSuntingLocal(params)
        this.clearChecked()
        this.getData()
        swal.fire({
          icon: 'success',
          title: 'Sunting Berhasil',
          text: 'Berhasil melakukan sunting',
          // confirmButtonColor: '#15406A',
        })
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          text: 'Gagal melakukan submit sunting'
        })
      } finally {
        loadprg.close()
        closeModal.click()
      }
    },
    toSunting (data) {
      this.modal.list = []
      this.modal.current = data.parsePayload.suntingOnlyPayload
      this.modal.s_log_id = data.id
      this.modal.parsePayload = data.parsePayload
      this.modal.user_email = data.user_email
      let newData = {}
      const index = this.masterDetail.findIndex(item => item.id == data.detail_id)
      // + `(${data.kode_jabatan}) ${data.nama_jabatan}`
      let title = ''
      if (data.nama_jabatan) {
        title = `${this.masterDetail[index].name} ${data.nama_jabatan}(${data.kode_jabatan})`
      } else {
        title = `${this.masterDetail[index].name} ${data.informasi_id}`
      }
      this.modal.title = title
      const item = data.parsePayload.suntingOnlyPayload
      this.modal.detail = this.masterDetail[index].name
      this.getSelectedMaster(this.masterDetail[index].name)

      switch (this.masterDetail[index].name) {
        case 'Nama Jabatan':
          newData = {
            kode_jabatan: '',
            nama_jabatan: '',
            detail_name: item.detail_name,
            currVal: item.nama_jabatan,
            editedVal: '',
            komentar: ''
          }
          this.modal.list.push(newData)
          break;
        case 'Kode Jabatan':
          newData = {
            kode_jabatan: '',
            nama_jabatan: '',
            detail_name: item.detail_name,
            currVal: item.kode_jabatan,
            editedVal: '',
            komentar: ''
          }
          this.modal.list.push(newData)
          break;
        case 'Ringkasan Uraian Tugas':
          newData = {
            kode_jabatan: item[0].kode_jabatan,
            nama_jabatan: item[0].nama_jabatan,
            detail_name: item[0].detail_name,
            currVal: item[0].currVal,
            editedVal: item[0].editedVal,
            komentar: item[0].komentar
          }
          this.modal.list.push(newData)
          break;
        case 'Rincian Tugas':
          for (let i = 0; i < item.length; i++) {
            newData = {
              kode_jabatan: item[i].kode_jabatan,
              nama_jabatan: item[i].nama_jabatan,
              detail_name: item[i].detail_name,
              currVal: item[i].currVal,
              editedVal: item[i].editedVal,
              komentar: item[i].komentar,
              id: item[i].id
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Pendidikan':
          for (let i = 0; i < item.length; i++) {
            newData = {
              kode_jabatan: item[i].kode_jabatan,
              nama_jabatan: item[i].nama_jabatan,
              detail_name: item[i].detail_name,
              currVal: item[i].currVal,
              editedVal: item[i].editedVal,
              komentar: item[i].komentar,
              id: item[i].d_id,
              kode: item[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Pengetahuan Kerja':
          for (let i = 0; i < item.length; i++) {
            newData = {
              kode_jabatan: item[i].kode_jabatan,
              nama_jabatan: item[i].nama_jabatan,
              detail_name: item[i].detail_name,
              currVal: item[i].currVal,
              editedVal: item[i].editedVal,
              komentar: item[i].komentar,
              id: item[i].id
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Pelatihan Kerja':
          for (let i = 0; i < item.length; i++) {
            newData = {
              kode_jabatan: item[i].kode_jabatan,
              nama_jabatan: item[i].nama_jabatan,
              currVal: item[i].currVal,
              editedVal: item[i].editedVal,
              komentar: item[i].komentar,
              id: item[i].d_id,
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Upaya Fisik':
          for (let i = 0; i < item.length; i++) {
            newData = {
              kode_jabatan: item[i].kode_jabatan,
              nama_jabatan: item[i].nama_jabatan,
              detail_name: item[i].detail_name,
              currVal: item[i].currVal,
              editedVal: item[i].editedVal,
              komentar: item[i].komentar,
              id: item[i].d_id,
              kode: item[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Bakat':
          for (let i = 0; i < item.length; i++) {
            newData = {
              kode_jabatan: item[i].kode_jabatan,
              nama_jabatan: item[i].nama_jabatan,
              detail_name: item[i].detail_name,
              currVal: item[i].currVal,
              editedVal: item[i].editedVal,
              komentar: item[i].komentar,
              id: item[i].d_id,
              kode: item[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Minat':
          for (let i = 0; i < item.length; i++) {
            newData = {
              kode_jabatan: item[i].kode_jabatan,
              nama_jabatan: item[i].nama_jabatan,
              detail_name: item[i].detail_name,
              currVal: item[i].currVal,
              editedVal: item[i].editedVal,
              komentar: item[i].komentar,
              id: item[i].d_id,
              kode: item[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Tempramen':
          for (let i = 0; i < item.length; i++) {
            newData = {
              kode_jabatan: item[i].kode_jabatan,
              nama_jabatan: item[i].nama_jabatan,
              detail_name: item[i].detail_name,
              currVal: item[i].currVal,
              editedVal: item[i].editedVal,
              komentar: item[i].komentar,
              id: item[i].d_id,
              kode: item[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
      }
      this.$refs.suntingModalBtn.click()
    },
    modalSuntingAddData (title) {
      const tableBottom = this.$refs.tableBottom
      const newData = {
        title: title,
        kode_jabatan: this.data.kode_jabatan,
        kode_sector: this.data.kode_sector,
        kode_subsector: this.data.kode,
        currVal: `Tambahan data ${this.modal.detail}`,
        editedVal: '',
        komentar: ''
      }
      this.modal.list.push(newData)
      setTimeout(() => {
        tableBottom.scrollIntoView({behavior: 'smooth', block: 'end'});
      }, 200)
      // tableBottom.scrollTop = tableBottom.scrollHeight;
    },
    getSelectedMaster (val) {
      if (val == 'Pendidikan' || val == 'Upaya Fisik' || val == 'Bakat' || val == 'Minat' || val == 'Tempramen') {
        switch (val) {
          case 'Pendidikan':
            this.selectedMaster = 'pendidikan'
            break;
          case 'Upaya Fisik':
            this.selectedMaster = 'syaratfisik'
            break;
          case 'Bakat':
            this.selectedMaster = 'bakat'
            break;
          case 'Minat':
            this.selectedMaster = 'minat'
            break;
          case 'Tempramen':
            this.selectedMaster = 'tempramen'
            break;
        }
      }
    },
    async getMasterSunting () {
      try {
        const res = await apiSunting.phpGetSuntingMaster()
        console.log('phpmaster sunting', res)

        // let params = { keyword: this.data.kode_jabatan, limit: 25, offset: 0 } // express
        let params = { limit: 25, keyword: '' }
        // const res2 = await apiSunting.quickSearch3(params) // express
        // const res2 = await apiSunting.quickSearchPhpNonKeyword(params)
        const res2 = await apiSunting.getSearchKbji(params)

        // this.master.kodeJabatan = JSON.parse(JSON.stringify(res2.data)) // express
        console.log('res', res)
        this.master.kodeJabatan = JSON.parse(JSON.stringify(res2.data)) // php
        this.master.pendidikan = JSON.parse(JSON.stringify(res[0].data.map(data => { return { ...data, label: 'pendidikan' } }) ))
        this.options = JSON.parse(JSON.stringify(res[0].data.map(data => { return { ...data, label: 'pendidikan' } }).slice(0, 50) ))
        this.master.syaratfisik = JSON.parse(JSON.stringify(res[1].data.map(data => { return { ...data, label: 'syaratfisik' } })))
        this.master.bakat = JSON.parse(JSON.stringify(res[2].data.map(data => { return { ...data, label: 'bakat' } })))
        this.master.minat = JSON.parse(JSON.stringify(res[3].data.map(data => { return { ...data, label: 'minat' } })))
        this.master.tempramen = JSON.parse(JSON.stringify(res[4].data.map(data => { return { ...data, label: 'tempramen' } })))
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          text: 'Gagal mengambil data master'
        })
      }
    },
    async pendidikanMasterSearch (query) {
      try {
        if (query == '') {
          this.options = this.master.pendidikan.slice(0, 50)
          return ''
        }
        this.options = this.master.pendidikan.filter(data => {
          if (data.pendidikan.toLowerCase().includes(query.toLowerCase()))
          return data
        }).slice(0, 50)
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          text: 'Gagal melakukan remote search pendidikan'
        })
      }
    },
    async kodeJabatanMasterSearch (query) {
      try {
        let res = {}
        let params = { limit: 25, keyword: query } // php
        this.loadingKodeJabatanSearch = true
        res = await apiSunting.getSearchKbji(params) // php
        const data = res.data ? res.data : [] // php
        this.master.kodeJabatan = JSON.parse(JSON.stringify(data))
      } catch (err) {
        console.log('err', err)
        swal.fire({
          icon: 'error',
          text: 'Gagal melakukan remote search kode jabatan'
        })
      } finally {
        this.loadingKodeJabatanSearch = false
      }
    },
    clearChecked () {
      this.checkedSunting = []
    }
  }
}
</script>

<style>
  @import url('../../assets/styles/detail.css');
  @import url('../../assets/styles/scroller.css');

  .detail-clickable {
    color: white;
    cursor: pointer;
  }

  .sunting-btn {
    cursor: pointer;
  }

  .modal {
    z-index: 9000;
  }

  .el-select-dropdown {
    z-index: 9001 !important;
  }

  .el-select {
    /* display: block;
    padding-right: 8px; */
    width: 100%;
  }
</style>