import axios from 'axios'
const phpAddressLocal = 'http://127.0.0.1:8000'
const phpAddress = process.env.VUE_APP_PHP_API
const nodeAddress = 'http://localhost:8082'

const client = axios.create({
  baseURL: nodeAddress,
  json: true
})

const clientPhp = axios.create({
  baseURL: phpAddress,
  json: true
})

const clientPhpLocal = axios.create({
  baseURL: phpAddressLocal,
  json: true
})

export default {
  async execute(method, resource, data) {
    return client({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data
    })
  },
  async executePhp(method, resource, data, headers) {
    return clientPhp({
      method,
      url: resource,
      data,
      headers
    }).then(req => {
      return req.data
    })
  },
  async executePhpReturnAll(method, resource, data, headers) {
    return clientPhp({
      method,
      url: resource,
      data,
      headers
    }).then(req => {
      return req
    })
  },
  async executePhpLocal(method, resource, data) {
    return clientPhpLocal({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data
    })
  },
  getKJN (limit) {
    return this.execute('post', `/api/kjn/jabatan/kategori`, limit)
  },
  getKBJI (params) {
    return this.execute('get', `/api/search/kbjiList/${params.kode}/${params.level}/${params.limit}/${params.offset}`)
  },
  getKBJIDetail (params) {
    return this.execute('get', `/api/details/kbjiDetails/${params.kode}/${params.level}`)
  },
  getKjnAxios (data, address = phpAddress + '/api/kjn/jabatan/kategori') {
    return axios.post(address, data)
  },
  getTkaAxios (data, address = phpAddress + '/api/kjn/tka/jabatan/kategori') {
    return axios.post(address, data)
  },
  getDisabilitasAxios (data, address = phpAddress + '/api/kjn/disabilitas/jabatan/kategori') {
    return axios.post(address, data)
  },
  getGolongan (data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      address = phpAddress + '/api/kjn/jabatan/golongan'
    }
    return axios.post(address, data)
  },
  getGolonganTka (data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      address = phpAddress + '/api/kjn/tka/jabatan/golongan'
    }
    return axios.post(address, data)
  },
  getGolonganDisabilitas (data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      address = phpAddress + '/api/kjn/disabilitas/jabatan/golongan'
    }
    return axios.post(address, data)
  },
  getJabatan (data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      address = phpAddress + '/api/kjn/jabatan/pergolongan'
    }
    return axios.post(address, data)
  },

  getJabatanTka (data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      address = phpAddress + '/api/kjn/tka/jabatan/pergolongan'
    }
    return axios.post(address, data)
  },
  getJabatanDisabilitas (data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      address = phpAddress + '/api/kjn/disabilitas/jabatan/pergolongan'
    }
    return axios.post(address, data)
  },
  getKJNSearchBySector (keyword, data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      if (keyword) {
        address = phpAddress + `/api/kjn/search/jabatan/golongan/${keyword}`
      } else {
        address = phpAddress + `/api/kjn/search/jabatan/golongan/`
      }
    }
    return axios.post(address, data)
  },
  getTkaSearchBySector (keyword, data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      if (keyword) {
        address = phpAddress + `/api/kjn/tka/search/jabatan/golongan/${keyword}`
      } else {
        address = phpAddress + `/api/kjn/tka/search/jabatan/golongan/`
      }
    }
    return axios.post(address, data)
  },
  getDisabilitasSearchBySector (keyword, data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      if (keyword) {
        address = phpAddress + `/api/kjn/disabilitas/search/jabatan/golongan/${keyword}`
      } else {
        address = phpAddress + `/api/kjn/disabilitas/search/jabatan/golongan/`
      }
    }
    return axios.post(address, data)
  },
  getKJNSearchBySubsector (keyword, data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      if (keyword) {
        address = phpAddress + `/api/kjn/search/jabatan/pergolongan/${keyword}`
      } else {
        address = phpAddress + `/api/kjn/search/jabatan/pergolongan/`
      }
    }
    return axios.post(address, data)
    // if (address !== null) {
    //   return axios.post(address, data)
    // } else {
    //   return this.executePhp('post', `/api/kjn/search/jabatan/pergolongan/${keyword}`, data)
    // }
  },
  getTkaSearchBySubsector (keyword, data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      if (keyword) {
        address = phpAddress + `/api/kjn/tka/search/jabatan/pergolongan/${keyword}`
      } else {
        address = phpAddress + `/api/kjn/tka/search/jabatan/pergolongan/`
      }
    }
    return axios.post(address, data)
    // if (address !== null) {
    //   return axios.post(address, data)
    // } else {
    //   return this.executePhp('post', `/api/kjn/search/jabatan/pergolongan/${keyword}`, data)
    // }
  },
  getDisabilitasSearchBySubsector (keyword, data, nextPage = null) {
    let address = null
    if (nextPage !== null && nextPage !== undefined && nextPage !== '') {
      address = nextPage
    } else {
      if (keyword) {
        address = phpAddress + `/api/kjn/disabilitas/search/jabatan/pergolongan/${keyword}`
      } else {
        address = phpAddress + `/api/kjn/disabilitas/search/jabatan/pergolongan/`
      }
    }
    return axios.post(address, data)
    // if (address !== null) {
    //   return axios.post(address, data)
    // } else {
    //   return this.executePhp('post', `/api/kjn/search/jabatan/pergolongan/${keyword}`, data)
    // }
  },
  // KBJI php

  getGol1 (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/golonganpokok`
    console.log('addresss', address)
    return axios.post(address, data)
    // return this.executePhp('post', `/api/kbji2014/golonganpokok`, data)
  },
  getGol2 (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/subgolonganpokok`
    console.log('addresss', address)
    return axios.post(address, data)
    // return this.executePhp('post', `api/kbji2014/subgolonganpokok`, data)
  },
  getGol3 (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/golongan`
    return axios.post(address, data)
    // return this.executePhp('post', `/api/kbji2014/golongan`, data)
  },
  getGol4 (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/subgolongan`
    return axios.post(address, data)
    // return this.executePhp('post', `/api/kbji2014/subgolongan`, data)
  },
  getGol5 (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/jabatan`
    return axios.post(address, data)
    // return this.executePhp('post', `/api/kbji2014/jabatan`, data)
  },
  getSearchKBJIAll (keyword, data, nextPage) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/search/all/${keyword}`
    return axios.post(address, data)
  },
  getGol1Search (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/search/subgolonganpokok`
    console.log('addresss', address)
    return axios.post(address, data)
    // return this.executePhp('post', `api/kbji2014/subgolonganpokok`, data)
  },
  getGol2Search (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/search/subgolonganpokok`
    console.log('addresss', address)
    return axios.post(address, data)
    // return this.executePhp('post', `api/kbji2014/subgolonganpokok`, data)
  },
  getGol3Search (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/search/golongan`
    return axios.post(address, data)
    // return this.executePhp('post', `/api/kbji2014/golongan`, data)
  },
  getGol4Search (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/search/subgolongan`
    return axios.post(address, data)
    // return this.executePhp('post', `/api/kbji2014/subgolongan`, data)
  },
  getGol5Search (data, nextPage ) {
    const address = nextPage !== null && nextPage !== undefined && nextPage !== '' ? nextPage : phpAddress + `/api/kbji2014/search/jabatan`
    return axios.post(address, data)
    // return this.executePhp('post', `/api/kbji2014/jabatan`, data)
  },

  getKBJIDetailPhp (data) {
    return this.executePhp('post', `/api/kbji2014/detail`, data)
  },
  getSearchByKeyword (keyword, data, nextPage) {
    const address = nextPage ? nextPage : phpAddress + `/api/kbji2014/search/${keyword}`
    return axios.post(address, data)
    return this.executePhp('post', `/api/kbji2014/search/${keyword}`, data)
  },
  getAllDraftUser (user) {
    return this.execute('get', `/api/search/draft?user=${user}`)
  },
  getAllDraftUserPhp (params, headers) {
    // return this.executePhpReturnAll('post', `/api/kjn/sunting/draft/jabatan/all`, params, headers)
    return this.executePhp('post', `/api/kjn/sunting/draft/jabatan/all`, params, headers)
  },
  getAllDraftUserPhpLocal (params) {
    return this.executePhp('post', `/api/kjn/sunting/draft/jabatan/all`, params)
  },
  // /kjn/sunting/draft

  // Download Log
  postDownloadLog (params, header) {
    return this.executePhp('put', `/api/user/download/log`, params, header)
  }
  
}